@import "src/static/styles/base";

.header {
  background-color: $color-white;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 0 5px 0 $color-shadow;
  position: sticky;
  top: 0;
  z-index: 10;

  .header-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .notification {
      background-color: #F6F9FA;
      border-radius: 50px;
      margin-right: 20px;
      border: 1px solid #E7E9EF;
    }

    .user {
      background-color: #F6F9FA;
      border-radius: 10px;
      border: 1px solid #E7E9EF;

      .MuiAvatar-root {
        width: 30px;
        height: 30px;
      }
    }
  }
}
