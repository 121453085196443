@import "src/static/styles/base";

.magasin-card {
  .MuiButtonBase-root {
    background-color: #F6F9FA;
    color: #5C5C5C;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    transition-property: background-color, color;
    transition-duration: .5s;

    &:hover {
      background-color: #A91E23;
      color: $color-white;
    }
  }
}
