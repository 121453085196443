@import "base";

.login {
  background-image: linear-gradient(to bottom, #00000088, #4E1919),
  url('../images/login-bg.jpg');
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .logo {
    width: 260px;
    position: absolute;
    top: 30px;
  }

  form {
    background-color: #FFFFFFE5;
    border: 1px solid $color-shadow;
    padding: 120px 120px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    a {
      color: $color-link;
      text-decoration: underline;
    }

    .MuiButton-containedPrimary.Mui-disabled {
      background-color: #851123AA;
    }
  }
}
