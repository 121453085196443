@import "src/static/styles/base";

.sidebar {
  height: 100vh;
  background-color: $color-white;
  box-shadow: 1px 0 5px 0 $color-shadow;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: fit-content;

  .top {
    text-align: center;

    .logo {
      width: 160px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .logo-small {
      width: 45px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  .MuiList-root {
    min-width: 220px;

    .MuiListItem-root {
      padding: 0;

      .MuiButtonBase-root {
        padding: 15px 30px;

        .MuiListItemText-root .MuiTypography-root {
          font-weight: 600;
          color: #6A6A6A;
          font-size: 14px;
        }

        &.Mui-selected {
          border-right: 4px solid #A91E23;

          .MuiListItemText-root .MuiTypography-root {
            color: #A91E23;
          }
        }

        .MuiListItemIcon-root {
          min-width: 50px;

          .icon {
            width: 30px;
          }
        }
      }
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
  height: 0.5em;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #00000044;
  border-radius: 100px;
}
