@import "src/static/styles/base";

.app {
  display: flex;
  flex-direction: row;

  .content {
    background-color: $color-bg;
    width: 100%;
    min-width: 1250px;
    display: flex;
    flex-direction: column;
  }
}
