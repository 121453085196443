@import "base";

.dashboard {
  .action {
    cursor: pointer;
    min-height: 90px;

    .icon {
      width: 24px;
      display: block;
    }
  }

  .planning {
    .color-key {
      display: flex;
      flex-direction: row;
      align-items: center;

      .color-box {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }

  .user-status-card {
    background-color: #F6F9FA;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    padding: 10px;

    .date-chip {
      background-color: #FFFFFF;
      border-color: #E0E7FF;
      color: #6F97BA;
      min-width: 80px;
    }
  }
}
