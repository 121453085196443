.mf-card {
  background-color: #FFFFFF;
  border-radius: 10px;

  &.outlined {
    border: 1px solid #E7E9EF;
  }

  &.elevated {
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);
  }

  &.clickable {
    cursor: pointer;
    transition-property: box-shadow;
    transition-duration: .3s;

    &:hover {
      box-shadow: 0 1px 8px #0000001A;
    }
  }

  .head {
    padding: 10px 20px;
    background-color: #F8F8F8;
    border-bottom: 1px solid #E7E9EF;
    border-radius: 10px 10px 0 0;
  }
}
