// colors
$color-white: #ffffff;
$color-black: #000000;
$color-primary: #851123;
$color-bg: #F8F9FB;
$color-shadow: #00000029;
$color-link: #156DE6;

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "roboto", serif;
}

body::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #00000044;
  border-radius: 100px;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
